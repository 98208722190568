import { createAsyncThunk } from '@reduxjs/toolkit';
import { addClientAPI, deleteClientAPI, fetchClientAPI, fetchClientInfoAPI, updateClientInfoAPI } from '../../services/client';
import { setLoading, setMessage, setError, setClients, setSelectedClient } from './slice';

export const addClient = createAsyncThunk(
	'client/add',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await addClientAPI(body);
			
			dispatch(setMessage('ADD_SUCCESS'));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)

export const fetchClient = createAsyncThunk(
	'client/list',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await fetchClientAPI(body);
			
			dispatch(setClients(result.data.data));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)


export const fetchClientInfo = createAsyncThunk(
	'client/info',
	async (id, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await fetchClientInfoAPI(id);
			
			dispatch(setSelectedClient(result.data.data));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)

export const deleteClient = createAsyncThunk(
	'client/delete',
	async (id, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await deleteClientAPI(id);
			dispatch(setClients(result.data.data));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)

export const updateClientInfo = createAsyncThunk(
	'client/update',
	async ({id, body}, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await updateClientInfoAPI(id, body);
			
			dispatch(setMessage('UPDATE_SUCCESS'));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)
