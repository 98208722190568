
import {  Radio, Checkbox, Form } from 'antd';

const RecycleForm = ({
	recycleOptions
}) => {

	return (
		<div>
			<h3>Choose recycling options *</h3>

			<Form.Item 
			
				name="bottle"
				label="Bottle"
			>
				<Radio.Group>
					{
						recycleOptions.bottle.map((item, index) => {
							return (
								<Radio key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Radio>
							)
						})
					}
				</Radio.Group>
			</Form.Item>

			<Form.Item 
				label="Cork"
				name="cork"
			>
				<Checkbox.Group>
					{
						recycleOptions.cork.map((item, index) => {
							return (
								<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</Form.Item>

			<Form.Item 
				label="Muzzle plate"
				name="muzzlePlate"
			>
				<Checkbox.Group>
					{
						recycleOptions.muzzlePlate.map((item, index) => {
							return (
								<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</Form.Item>

			<Form.Item 
				label="Muselet"
				name="muselet"
			>
				<Checkbox.Group>
					{
						recycleOptions.muselet.map((item, index) => {
							return (
								<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</Form.Item>

			<Form.Item 
				label="Cap"
				name="cap"
			>
				<Radio.Group>
					{
						recycleOptions.cap.map((item, index) => {
							return (
								<Radio key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Radio>
							)
						})
					}
				</Radio.Group>
			</Form.Item>

			<Form.Item 
				label="Capsule (still wine)"
				name="capsule"
			>
				<Checkbox.Group>
					{
						recycleOptions.capsule.map((item, index) => {
							return (
								<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</Form.Item>

			<Form.Item 
				label="Cardboard "
				name="cardboard"
			>
				<Checkbox.Group>
					{
						recycleOptions.cardboard.map((item, index) => {
							return (
								<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</Form.Item>

			<Form.Item 
				label="Shim(s) (bottle separator(s))"
				name="shim"
			>
				<Radio.Group>
					{
						recycleOptions.shim.map((item, index) => {
							return (
								<Radio key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Radio>
							)
						})
					}
				</Radio.Group>
			</Form.Item>

			<Form.Item 
				label="Cardboard case"
				name="cardboardCase"
			>
				<Checkbox.Group>
					{
						recycleOptions.cardboardCase.map((item, index) => {
							return (
								<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</Form.Item>

			<Form.Item 
				label="Wooden box"
				name="woodenBox"
			>
				<Checkbox.Group>
					{
						recycleOptions.woodenBox.map((item, index) => {
							return (
								<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
									{item}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</Form.Item>
		</div>
	)
}

export default RecycleForm;
