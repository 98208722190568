import { Form, Button, Checkbox } from "antd";
// import { INGREDIENTS } from "../contant";
import ReOrderItems from "./itemReorder";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


// const fixedItems = [
// 	'Grape',
// 	'Sucrose/sugar',
// 	'Concentrated grape must or rectified concentrated grape must',
// ]
let isFirstTime = false;
const IngredientsForm = ({
	onNextCick,
	onPrevClick,
	initialValues,
}) => {
	const { formInfo } = useSelector((state) => state.product)
	const { INGREDIENTS } = formInfo;
	
	const [orderOfSelectedItems, setOrderOfSelectedItems] = useState(
		initialValues && initialValues.orderOfSelectedItems || []
	);
	const [fixedItems, setFixedItems] = useState([]);
	const onFinish = (values) => {
		onNextCick({
			...values,
			orderOfSelectedItems: [...fixedItems, ...orderOfSelectedItems]
		});
	};

	const [form] = Form.useForm();
	const values = Form.useWatch([], form);


	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};


	useEffect(() => {
		if(initialValues.orderOfSelectedItems) {
			setOrderOfSelectedItems([...initialValues.orderOfSelectedItems]);
		}

		// isFirstTime = true;

		return () => {
			isFirstTime = false;
		}
	}, [])
	
	const updateOrder = (data) => {
		if(orderOfSelectedItems.length === 0) {
			setOrderOfSelectedItems([...data]);
			return;
		}

		const newArrayWithOrder = [];

		for(const i in orderOfSelectedItems) {
			const index = data.findIndex((x) => x === orderOfSelectedItems[i]);
			if(index > -1) {
				newArrayWithOrder.push(orderOfSelectedItems[i]);
			}
		}

		for(const i in data) {
			const index = newArrayWithOrder.findIndex((x) => x === data[i]);

			if(index === -1) {
				newArrayWithOrder.push(data[i])
			}
		}

		const { fixedOrderItems } = formInfo;
		const availabledFixedItems = [];

		for(const i in fixedOrderItems) {
			const index = newArrayWithOrder.findIndex((x) => x === fixedOrderItems[i]);

			if(index > -1) {
				availabledFixedItems.push(fixedOrderItems[i]);
				newArrayWithOrder.splice(index, 1);
			} 
		}

		const finalArray = [...newArrayWithOrder];

		setFixedItems([...availabledFixedItems])

		setOrderOfSelectedItems([...finalArray]);
	}

	useEffect(() => {
		if(!isFirstTime) {
			isFirstTime = true;
			return;
		}
		const data = [...new Set([
			...values?.acidityRegulators || [],
			...values?.allergens || [],
			...values?.commodity || [],
			...values?.conservative || [],
			...values?.drawingLiquor || [],
			...values?.enrichment || [],
			...values?.expeditionLiqueur || [],
			...values?.other || [],
			...values?.packagingGas || [],
			...values?.stabilizers || [],
		])];

		updateOrder([...data]);
	}, [values]);
	

	return (
		<div className="step2">
			<Form
				form={form}
				name="basic"
				layout="vertical"
				initialValues={initialValues}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					label="Commodity/raw material"
					name="commodity"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.commodity.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Enrichment/sweetening"
					name="enrichment"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.enrichment.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Conservative"
					name="conservative"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.conservative.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Acidity regulators"
					name="acidityRegulators"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.acidityRegulators.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Stabilizers"
					name="stabilizers"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.stabilizers.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Drawing liquor"
					name="drawingLiquor"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.drawingLiquor.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Expedition liqueur"
					name="expeditionLiqueur"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.expeditionLiqueur.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Packaging gas"
					name="packagingGas"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.packagingGas.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>

				<Form.Item
					label="Other ingredients"
					name="other"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.other.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>

				<Form.Item
					label="Allergens"
					name="allergens"
					className="full_width"
				>
					<Checkbox.Group>
						{
							INGREDIENTS.allergens.map((item, index) => {
								return (
									<Checkbox key={index} value={item} style={{ lineHeight: '32px' }}>
										{item}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item className="full_width">
					<ReOrderItems 
						orders={orderOfSelectedItems} 
						setOrder={setOrderOfSelectedItems} 
						fixedItems={fixedItems}
					/>
				</Form.Item>
				<div className='products_form_buttons'>
					<Form.Item>
						<Button htmlType="button" onClick={onPrevClick}>
							Previous
						</Button>
						<Button type="primary" htmlType="submit">
							Next
						</Button>
					</Form.Item>
				</div>
			</Form>
		</div>
	)
}

export default IngredientsForm;
