import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminLoginAPI, clientLoginAPI, forgotPasswordAPI, updatePasswordAPI } from '../../services/auth';
import { setLoading, setUser, setError, setMessage } from './slice';
import { jwtDecode } from "jwt-decode";
import { message } from 'antd';

export const adminLogin = createAsyncThunk(
	'admin/login',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await adminLoginAPI(body);
			const token = result.data.data;
			const user = jwtDecode(token);
			localStorage.setItem('token', token);
			dispatch(setUser(user));
		} catch (err) {
			console.log(err);
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
		} finally {
			dispatch(setLoading(false));
		}
	}
)

export const clientLogin = createAsyncThunk(
	'client/login',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await clientLoginAPI(body);
			const token = result.data.data;
			const user = jwtDecode(token);
			localStorage.setItem('token', token);
			dispatch(setUser(user));
		} catch (err) {
			console.log(err);
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
		} finally {
			dispatch(setLoading(false));
		}
	}
)


export const forgotPassword = createAsyncThunk(
	'client/forgot-password',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await forgotPasswordAPI(body);
			dispatch(setMessage('FORGOT_PASSWORD'));
			message.success(result.data.message);
		} catch (err) {
			console.log(err);
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
		} finally {
			dispatch(setLoading(false));
		}
	}
)

export const updatePassword = createAsyncThunk(
	'client/update-password',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await updatePasswordAPI(body);
			dispatch(setMessage('UPDATE_PASSWORD'));
			message.success(result.data.message);
		} catch (err) {
			console.log(err);
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
		} finally {
			dispatch(setLoading(false));
		}
	}
)
