import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import {
	BrowserRouter,
  } from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { messagesInEng } from './utils/text';
import './i18n';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Wrapper = () => {
	return (
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: '#1E3D52',
					},
				}}
			>
				<Provider store={store}>
					<BrowserRouter basename="/">
						<App />
					</BrowserRouter>
				</Provider>
			</ConfigProvider>
	)
}

root.render(
//   <React.StrictMode>
    <Wrapper />
//   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

