import { createAsyncThunk } from '@reduxjs/toolkit';
import { addProductAPI, duplicateProductAPI, fetchProductInfoAPI, fetchProductsAPI, formDataProductAPI, updateProductInfoAPI } from '../../services/product';
import { setLoading, setMessage, setError, setProducts, setSelectedProduct, setFormInfo, setFetchingInfo  } from './slice';

export const addProduct = createAsyncThunk(
	'product/add',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await addProductAPI(body);
			
			dispatch(setMessage('ADD_SUCCESS'));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)

export const fetchProduct = createAsyncThunk(
	'product/list',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await fetchProductsAPI();
			
			dispatch(setProducts(result.data.data));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)

export const fetchProductInfo = createAsyncThunk(
	'product/info',
	async ({id, language}, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await fetchProductInfoAPI(id, language);
			
			dispatch(setSelectedProduct(result.data.data));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)


export const updateProductInfo = createAsyncThunk(
	'product/update',
	async ({body, id}, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await updateProductInfoAPI(id, body);
			
			dispatch(setMessage('UPDATE_SUCCESS'));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)


export const duplicateProductInfo = createAsyncThunk(
	'product/duplicate',
	async (body, { dispatch }) => {
	  	try {
			dispatch(setLoading(true));
			const result = await duplicateProductAPI(body);
			
			dispatch(setMessage('UPDATE_SUCCESS'));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setLoading(false));
		}
	}
)

export const formProductInfo = createAsyncThunk(
	'product/form-data',
	async (_params, { dispatch }) => {
	  	try {
			dispatch(setFetchingInfo(true));
			const result = await formDataProductAPI();
			
			dispatch(setFormInfo(result.data.data));
		} catch (err) {
			if(err.response.data.message) {
				dispatch(setError(err.response.data.message));
				return;
			}
			dispatch(setError(err.message));
			
		} finally {
			dispatch(setFetchingInfo(false));
		}
	}
)
