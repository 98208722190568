import { Form, Button, Input, Modal, Typography, Row, Col, Alert, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const { Title, Text } = Typography;

const NutritionForm = ({
	onNextCick,
	onPrevClick,
	initialValues = {
		fat: 0,
		alcohol: 0,
		fattyAcid: 0,
		carbohydrates: 0,
		sugars: 0,
		protein: 0,
		salt: 0,
		kj_value: 0,
		totalkcal: 0,
	},
	isEditMode = false,
	isDuplicateMode = false
}) => {
	const { loading, formInfo: { categoryList } } = useSelector(state => state.product);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState('');

	const columns = [
		{
			title: 'Category',
			dataIndex: 'categoryName',
			key: 'categoryName',
		},
		{
			title: 'Sub-category',
			dataIndex: 'subCategoryName',
			key: 'subCategoryName',
		},
		{
			title: 'Reference volume',
			dataIndex: 'volume',
			key: 'volume',
		},
		{
			title: 'Energy',
			dataIndex: 'energy',
			key: 'energy',
		},
	];

	const onFinish = (values) => {
		onNextCick({
			...values,
			selectedRow,
			selectedCategoryItem: categoryList.find((x) => x.index === selectedRow[0]),
			mode
		});
	};

	const [selectedRow, setSelectedRow] = useState(initialValues?.selectedRow || []);
	
	const [form] = Form.useForm();
	const values = Form.useWatch([], form);

	useEffect(() => {
		const obj = {
			fat: 0,
			alcohol: 0,
			fattyAcid: 0,
			carbohydrates: 0,
			sugars: 0,
			protein: 0,
			salt: 0,
			kj_value: 0,
			totalkcal: 0
		};
		form.setFieldsValue({
			...obj,
			...initialValues
		});

	}, [initialValues]);

	const { sugars = 0, alcohol = 0, kj_value = 0, totalkcal = 0 } = values || {};

	const cal = () => {
		const totalkcal = Math.round(alcohol * 0.8 * 7.1 + sugars * 4);
		const kj_value = Math.round(totalkcal * 4.184);
		setMode('custom');
		setSelectedRow([]);
		form.setFieldsValue({
			...values,
			totalkcal,
			kj_value,
		})
	}
	const onCalChange = (value) => {
		const kj_value = Math.round(value * 4.184);
		form.setFieldsValue({
			...values,
			kj_value
		})
	}

	useEffect(() => {
		const totalkcal = Math.round(kj_value / 4.184);
		form.setFieldsValue({
			...values,
			totalkcal
		})
	}, [kj_value])

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		setMode('standard')
		const item = categoryList.find((x) => x.index === selectedRow[0]);
		form.setFieldsValue({
			...values,
			kj_value: item.kj,
			totalkcal: item.kcal
		});
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<div>
			<Form
				form={form}
				name="basic"
				layout="vertical"
				initialValues={initialValues}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
				className="nutrition_form"
			>
				<Alert
					message="Advice in order to avoid a risk of confusion in the mind of customer , we advise you to use the same energy value as that printed on your label"
					type="info"
					showIcon
				/>

				<div className="input_group">
					<Form.Item
						label="Alcohol"
						name="alcohol"
						rules={[
							{
								required: false,
								message: 'Please enter value',
							},
						]}
					>
						<Input type="number" suffix="%" />
					</Form.Item>
					<Form.Item
						label="Fat/Lipids (per 100 ml)"
						name="fat"
						rules={[
							{
								required: false,
								message: 'Please enter value',
							},
						]}
					>
						<Input type="number" disabled suffix="g" />
					</Form.Item>
				</div>
				<div className="input_group">
					<Form.Item
						label="Saturated fatty acids (per 100 ml)"
						name="fattyAcid"
						rules={[
							{
								required: false,
								message: 'Please enter value',
							},
						]}
					>
						<Input type="number" disabled suffix="g" />
					</Form.Item>
					<Form.Item
						label="Carbohydrates (per 100 ml)"
						name="carbohydrates"
						rules={[
							{
								required: false,
								message: 'Please enter value',
							},
						]}
					>
						<Input type="number" suffix="g" />
					</Form.Item>
				</div>
				<div className="input_group">
					<Form.Item
						label="Sugars (per 100 ml)"
						name="sugars"
						rules={[
							{
								required: false,
								message: 'Please enter value',
							},
						]}
					>
						<Input type="number" suffix="g" />
					</Form.Item>
					<Form.Item
						label="Protein (per 100 ml)"
						name="protein"
						rules={[
							{
								required: false,
								message: 'Please enter value',
							},
						]}
					>
						<Input type="number" disabled suffix="g" />
					</Form.Item>
				</div>
				<div className="input_group">
					<Form.Item
						label="Salt (per 100 ml)"
						name="salt"
						rules={[
							{
								required: false,
								message: 'Please enter value',
							},
						]}
					>
						<Input type="number" disabled suffix="g" />
					</Form.Item>
					<Form.Item
						label="Protein (per 100 ml)"
						name="protein"
						rules={[
							{
								required: false,
								message: 'Please enter value',
							},
						]}
					>
						<Input type="number" disabled suffix="g" />
					</Form.Item>
				</div>

				<div className="category_from_section">
					<div className="category_from_item">
						<strong>Category</strong>
						<span>Wine</span>
					</div>
					<div className="category_from_item">
						<strong>Sub-category</strong>
						<span>Wine of France</span>
					</div>
					<div className="category_from_item">
						<strong>Reference volume</strong>
						<span>100ml</span>
					</div>
					<div className="category_from_item category_from_item_last">
						<strong>Energy reference</strong>
						<div className="category_from_inputs">
							<div className="category_input">
								<Form.Item
									name="kj_value"
									rules={[
										{
											required: false,
											message: 'Please enter value',
										},
									]}
								>
									<Input type="number" />
								</Form.Item>
								<label>KJ /</label>
							</div>
							<div className="category_input">
								<Form.Item
									name="totalkcal"
									rules={[
										{
											required: false,
											message: 'Please enter value',
										},
									]}
								>
									<Input type="number" onChange={(e) => {
										onCalChange(e.target.value);
										// console.log("JB VALU", e.target.value);
									}} />
								</Form.Item>
								<label>calories</label>
							</div>
						</div>
					</div>
				</div>

				<div className="nutrition_buttons">
					<Button type="primary" onClick={cal} disabled={loading} htmlType="button">
						Generate the energy value according to the nutritional declaration entered
					</Button>
					<span className="last_step_or">OR</span>
					<Button type="primary" disabled={loading} onClick={() => setIsModalOpen(true)} htmlType="button">
						Use the standard energy value for your wine category
					</Button>
					<span className="last_step_or">OR</span>
					<Button type="primary" htmlType="button">
						Fill everything out myself (after laboratory analysis)
					</Button>
				</div>
				<div className='products_form_buttons'>
					<Form.Item>
						<Button htmlType="button" disabled={loading} onClick={onPrevClick}>
							Previous
						</Button>
						<Button type="primary" loading={loading} htmlType="submit">
							{
								isEditMode ?
									(isDuplicateMode ? 'Create Duplicate' : 'Update') :
									'Create'
							}
						</Button>
					</Form.Item>
				</div>

				<Modal
					title="Select category for the wine"
					open={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
					closable={false}
					width={915}
					className="category_modal"
				>
					<div className="category_modal_wrapper">
						<div className="category_table">
							<Table
								rowKey={'index'}
								size="small" 
								
								dataSource={categoryList} 
								columns={columns} 
								rowSelection={{
									hideSelectAll: true,
									selectedRowKeys: selectedRow,
									renderCell: () => {
										return null;
									}
								}}
								pagination={false}
								onRow={(record) => {
									return {
									  onClick: () => {
										setSelectedRow([record.index])
									  },
									};
								}}
							/>
						</div>
					</div>
				</Modal>
			</Form>
		</div>
	)
}

export default NutritionForm;
