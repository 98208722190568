import { config } from '../utils/constant';
import http from './http';

const URL = config.API_URL;

export const addProductAPI = (body) => {
	return http.post(URL+'/product/create', body);
}

export const fetchProductsAPI = () => {
	return http.get(URL+'/product');
}

export const fetchProductInfoAPI = (id, language) => {
	return http.get(`${URL}/product/${id}?language=${language}`);
}

export const updateProductInfoAPI = (id, body) => {
	return http.put(`${URL}/product/${id}`, body)
}

export const duplicateProductAPI = (body) => {
	return http.post(`${URL}/product/duplicate`, body)
}

export const formDataProductAPI = () => {
	return http.get(`${URL}/product-form/data`)
}
