import { config } from '../utils/constant';
import http from './http';


const URL = config.API_URL;

export const adminLoginAPI = (body) => {
	return http.post(URL+'/auth/admin-login', body);
}

export const clientLoginAPI = (body) => {
	return http.post(URL+'/auth/client-login', body);
}

export const forgotPasswordAPI = (body) => {
	return http.post(URL+'/auth/forgot-password', body);
}

export const updatePasswordAPI = (body) => {
	return http.post(URL+'/auth/update-password', body);
}
