export const WINE_COLORS = [
	{
		label: 'Red',
		value: 'red'
	},
	{
		label: 'Pink',
		value: 'pink'
	},
	{
		label: 'White',
		value: 'white'
	}
]

export const WINE_CLASSIFICATION = [
	{
		label: 'Wine of France',
		value: 'Wine of France'
	},
	{
		label: 'AOC',
		value: 'AOC'
	},
	{
		label: 'PGI',
		value: 'PGI'
	}
]

export const WINE_REGION = [
	{
		label: 'Burgundy',
		value: 'Burgundy'
	},
	{
		label: 'Beaujolais',
		value: 'Beaujolais'
	},
	{
		label: 'Jura',
		value: 'Jura'
	},
	{
		label: 'Champagne',
		value: 'Champagne'
	},
	{
		label: 'Other',
		value: 'Other'
	}
]


export const config = {
	API_URL: process.env.NODE_ENV === 'production' ? `${window.location.origin}/api` :'http://localhost:3001/api',
	PREFIX: 'https://wine-app.s3.eu-north-1.amazonaws.com',
	IMAGE_URL: process.env.NODE_ENV === 'production' ? `${window.location.origin}/images` : 'http://localhost:3001/images'
}
