import { useNavigate, useParams } from "react-router-dom";
import ProductForm from "./components/form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { duplicateProductInfo, formProductInfo, fetchProductInfo } from "../../redux/product/action";
import { Spin } from "antd";
import { setMessage, setSelectedProduct } from "../../redux/product/slice";

const DuplicateProduct = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = params;
	const { msg, selectedProduct, loading } = useSelector(state => state.product);

	useEffect(() => {
		dispatch(formProductInfo());
		dispatch(fetchProductInfo(id));

		return () => {
			dispatch(setSelectedProduct(null));
		}
	}, []);

	const getValue = () => {
		return {
			...selectedProduct,
			basic: {
				...selectedProduct.basic,
				photo: ''
			}
		}
	}

	useEffect(() => {
		if(msg === "UPDATE_SUCCESS") {
			dispatch(setMessage(null));
			navigate("/products")
		}
	}, [msg]);

	const submit = (formData) => {
		dispatch(duplicateProductInfo(formData))
	}

	return (
		<div className="products_form">
			<div className="container">
				<h2>Create Duplicate product</h2>
				<Spin spinning={loading}>
					{selectedProduct && <ProductForm
					formValues={getValue()} 
					submit={submit} 
					isEditMode={true}
					isDuplicateMode={true}
					/>}
				</Spin>
			</div>
		</div>
	)
}

export default DuplicateProduct;
