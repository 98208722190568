import React, { useEffect, useState } from "react";
import ForgotPasswordForm from "../../components/forgot-password";
import { useDispatch, useSelector } from "react-redux";
import { clientLogin, forgotPassword, updatePassword } from "../../redux/auth/action";
import { useNavigate } from "react-router-dom";
import OTPVerifyForm from "../../components/forgot-password/otpVerify";
import { setMessage } from "../../redux/product/slice";

const ForgotPassword = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const  { isLoggedIn, error, msg } = useSelector((state) => state.auth);

	const [email, setEmail] = useState('');
	const [view, setView] = useState('EMAIL');

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/products')
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if(msg === 'FORGOT_PASSWORD') {
			setView('OTP');
			dispatch(setMessage(null));
		}
		if(msg === 'UPDATE_PASSWORD') {
			navigate("/login");
			dispatch(setMessage(null));
		}
	}, [msg]);

	const onSubmit = ({email}) => {
		dispatch(forgotPassword({email}));
		setEmail(email);
	}

	const onUpdatePassword = (body) => {
		dispatch(updatePassword(body));
	}

	return (
		<div>
			{
				view == 'EMAIL' && (
					<ForgotPasswordForm type='client' onSubmit={onSubmit} error={error}/>
				)
			}
			{
				view == 'OTP' && (
					<OTPVerifyForm email={email} onSubmit={onUpdatePassword} error={error}/>
				)
			}
		</div>
	)
}

export default ForgotPassword;
