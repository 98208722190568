import React from 'react';
import { Button, Card, Checkbox, Form, Input } from 'antd';
import "./style.css";
import { useTranslation } from 'react-i18next';
import Error from '../error';
import { Link } from 'react-router-dom';
import backgroundImage from '../../assets/newBg.jpeg';
import logo from '../../assets/Logo.png';

const LoginForm = ({
	onSubmit,
	error,
	type
}) => {
	const { t } = useTranslation();

	const onFinish = (values) => {
		onSubmit(values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	
	return (
		<div className='login_main'>
			<div className='login_container'>
				<div className='login_image'>
					<img src={backgroundImage} />
				</div>
				<div className='login_form_container'>
					<div className='login_form'>
						<img src={logo} className='logo' />
						<p>Enter your credentials to access your account</p>
						<Form
							name="basic"
							layout="vertical"
							labelCol={{
								span: 8,
							}}
							initialValues={{
								email: '',
								password: ''
							}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
						>
							<Form.Item
								name="email"
								rules={[
									{
										required: true,
										message: 'Please enter your email',
									},
									{
										type: 'email',
										required: true,
										message: 'Please enter valid email',
									},
								]}
							>
								<Input placeholder="Identifier" />
							</Form.Item>
					
							<Form.Item
								name="password"
								rules={[
								{
									required: true,
									message: 'Please enter your password!',
								},
								]}
							>
								<Input.Password placeholder="Password" />
							</Form.Item>
							<Error error={error}/>
							<Form.Item>
								<Button type="primary" htmlType="submit" className='login-btn'>
									Login
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</div>
	)
}
export default LoginForm;
