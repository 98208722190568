import { Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


const columns = [];

const RecycleView = ({
	value,
	language
}) => {
	const { formInfo: { recycleOptions, recycleViewList	} } = useSelector(state => state.product);
	const [items, setItems] = useState([])

	useEffect(() => {
		
		const items = []

		for(const i in recycleOptions) {

			if(value[i]) {
				if(typeof value[i] === 'string') {
					const result = recycleViewList.find((x) => x.key === i && x.value == value[i])
					if(result) {
						items.push(result);
					}
				} else {
					const values = value[i];
					for(const j in values) {
						const result = recycleViewList.find((x) => x.key === i && x.value == value[i])
						if(result) {
							items.push(result);
						}
					}
				}

			}
		}
		setItems(items);
	}, [language]);


	const getColumns = () => {
		if(language === 'en') {
			return [
				{
					title: '',
					dataIndex: 'title',
					key: 'title',
				},
				{
					title: 'English',
					dataIndex: 'eng',
					key: 'eng',
				}
			]
		} 

		return [
			{
				title: '',
				dataIndex: 'titleIt',
				key: 'titleIt',
			},
			{
				title: 'Italian',
				dataIndex: 'italian',
				key: 'italian',
			}
		]
	}

	return (
		<div>
			<Table
				dataSource={items} 
				columns={getColumns()} 
				rowKey={'index'}
				pagination={false}
			/>
		</div>
	)
}

export default RecycleView;
