import React, {useState} from 'react';
import { Layout, Popconfirm, Tooltip, Typography, Dropdown, Space, Button, Modal } from 'antd';
import { LogoutOutlined, DownOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import "./style.css";
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/auth/slice';
import logo from '../../assets/Logo.png';
import productIcon from '../../assets/images/product_icon.svg';
import clientIcon from '../../assets/images/client_icon.svg';
import logoutIcon from '../../assets/images/logout_icon.svg';
import warningIcon from '../../assets/images/warning_icon.svg';
import downIcon from '../../assets/images/chevron_down.svg';

const { Header } = Layout;
const { Text } = Typography;



const HeaderView = ({user}) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const logoutClick = () => {
		localStorage.clear();
		dispatch(logout())
		navigate('/login');
	}

	const getLink = () => {
		if(user.type === 'admin') {
			return '/clients';
		}
		return '/products'
	}

	const getName = () => {
		if (user.type === 'admin') {
			return (
				<div className='menu_item'>
					<img src={clientIcon} alt="Clients" />
					<span>Clients</span>
				</div>
			);
		}
		
		return (
			<div className='menu_item'>
				<img src={productIcon} alt="Products" />
				<span>Products</span>
			</div>
		);
	}
	
	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const items = [
		{
		  key: '1',
		  label: (
			<div className='user_details'>
			  <strong>Vin-code</strong>
			  {user.email}
			</div>
		  )
		},
		{
		  key: '2',
		  label: (
			<Button onClick={showModal}>
			  <img src={logoutIcon} alt="Logout" /> Logout
			</Button>
		  )
		}
	  ];

	return (
		<Header className='header'>
			<div className='container'>
				<div className='header_wrapper'>
					<Link to={getLink()} className='logo'>
						<img src={logo} />
					</Link>
					<div className='header_menu'>
						<Link to={getLink()}>
							{getName()}
						</Link>
						<div className='separator_line' />
						<Dropdown menu={{ items }} trigger={['click']} className='header_dropdown'>
							<a onClick={(e) => e.preventDefault()}>
								Vin-code
								<img src={downIcon} />
							</a>
						</Dropdown>

					</div>
				</div>
			</div>
			<Modal open={isModalOpen} footer={null} closeIcon={false} className='logout_modal' width={368} onCancel={handleCancel}>
				<img src={warningIcon} />
				<h2>Logout Confirmation</h2>
				<p>Are you sure you want to logout ?</p>
				<div className='logout_buttons'>
					<Button onClick={handleCancel} className='logout_cancel'>No</Button>
					<Button onClick={logoutClick} className='logout_confirm'>Yes,Sure</Button>
				</div>
			</Modal>
			
		</Header>
	)
}

export default HeaderView;
