import React, { useEffect } from "react";
import LoginForm from "../../components/login";
import { useDispatch, useSelector } from "react-redux";
import { adminLogin } from "../../redux/auth/action";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const  { isLoggedIn, error } = useSelector((state) => state.auth);

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/clients')
		}
	}, [isLoggedIn]);

	const onSubmit = (body) => {
		dispatch(adminLogin(body));
	}

	return (
		<div>
			{/* Admin login */}
			<LoginForm type='admin' onSubmit={onSubmit} error={error}/>
		</div>
	)
}

export default AdminLogin;
