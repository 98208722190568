import React from 'react';
import { Button, Card, Form, Input } from 'antd';
import Error from '../../../components/error';
import backIcon from '../../../assets/images/back_icon.svg';


const ClientForm = ({
	isEditMode,
	onSubmit,
	loading,
	error,
	navigate,
	id = null,
	selectedClient = null
}) => {
	const onFinish = (values) => {
		if(isEditMode) {
			onSubmit({
				id,
				body: values
			})
			return;
		}
		onSubmit(values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	
	return (
		<div className='client_from_container'>
			<div className='client_from_header'>
				<Button onClick={() => { navigate(-1); }} className='back_button'><img src={backIcon} /></Button>
				<h2>{isEditMode ? 'Edit Client' : 'Add New Client'}</h2>
			</div>
			<Form
				name="basic"
				layout="vertical"
				labelCol={{
					span: 8,
				}}
				initialValues={{
					email: selectedClient ? selectedClient.email : '',
					password: '',
					name: selectedClient ? selectedClient.name : '',
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					label="Client Name"
					name="name"
					rules={[
						{
							required: true,
							message: 'Please enter client name',
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Email"
					name="email"
					rules={[
						{
							required: true,
							message: 'Please enter client email',
						},
						{
							type: 'email',
							required: true,
							message: 'Please enter valid email',
						},
					]}
				>
					<Input disabled={isEditMode} />
				</Form.Item>
		
				<Form.Item
					label="Password"
					name="password"
					rules={[
					{
						required: !isEditMode,
						message: 'Please enter client password!',
					},
					]}
				>
					<Input.Password />
				</Form.Item>
				<Error error={error}/>
				<Form.Item className='clients_form_buttons'>
					<Button onClick={() => { navigate(-1); }}>
						Cancel
					</Button>
					<Button loading={loading} disabled={loading} type="primary" htmlType="submit">
						{isEditMode ? 'Save Changes' : 'Add'}
					</Button>
				</Form.Item>
			</Form>
			
		</div>
	)
}
export default ClientForm;
