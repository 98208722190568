import React from 'react';
import { Button, Card, Checkbox, Form, Input } from 'antd';
import "./style.css";
// import { useTranslation } from 'react-i18next';
import Error from '../error';

const ForgotPasswordForm = ({
	onSubmit,
	error,
}) => {
	// const { t } = useTranslation();

	const onFinish = (values) => {
		onSubmit(values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	
	return (
		<div className='login_main'>
			<div className='login_container'>
				<div className='login_form'>
					<h2>Forgot password</h2>
					<Form
						name="basic"
						layout="vertical"
						labelCol={{
							span: 8,
						}}
						initialValues={{
							email: '',
							password: ''
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<Form.Item
							label="Identifier"
							name="email"
							rules={[
								{
									required: true,
									message: 'Please enter your email',
								},
								{
									type: 'email',
									required: true,
									message: 'Please enter valid email',
								},
							]}
						>
							<Input />
						</Form.Item>
				
						<Error error={error}/>
						<Form.Item>
							<Button type="primary" htmlType="submit" className='login-btn'>
								Forgot Password
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	)
}
export default ForgotPasswordForm;
