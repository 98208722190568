import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthWrapper = ({
	isLoggedIn,
	children
}) => {
	const navigate = useNavigate();
	useEffect(() => {
		if(!isLoggedIn) {
			return navigate('/login');
		}
	}, []);

	return children;
}


export default AuthWrapper;
