import React, { useEffect, useState } from 'react';
import { Collapse, Card, Typography, QRCode, Button, Tooltip, Avatar, Select } from "antd";
import { config } from "../../../utils/constant";
import '../style.css';
import { DownloadOutlined } from "@ant-design/icons";
import RecycleView from "./recycleView";
import chevronDownIcon from '../../../assets/images/chevron_down.svg';

const { Title, Text } = Typography;
const { Option } = Select;

const RenderView = ({
	result
}) => {
	if(result && result.image) {
		return (
			<Avatar 
				size={60}
				style={{margin: '5px'}}
				shape='square'
				src={`${config.IMAGE_URL}/${result.image}`}
			/>
		)
	}
	if(result && result.showText) {
		return (
			<Text>{result.label}</Text>
		)
	}
	return null;
}

const LabelView = ({
	labels,
	items,
	title,
	defaultActiveKey,
	type = 'with-image',
	language
}) => {
	if(type === 'nutrition') {
		return (
			<Collapse
				bordered={false}
				defaultActiveKey={[defaultActiveKey]}
				expandIconPosition='end'
				items={[
					{
						key: defaultActiveKey,
						label: title,
						children: (
							<div className="info_wrapper">
								<div className="info_item">
									<Text>{items.energyKey}</Text>
									<Text>{items.valueKey}/100ml</Text>
								</div>
								<div className="info_item">
									<Text>{items.fatsKey}</Text>
									<Text>{items.kj_value} kj/{items.totalkcal} kcal</Text>
								</div>
								<div className="info_item">
									<Text>{items.fattyAcidKey}</Text>
									<Text>0g</Text>
								</div>
								<div className="info_item">
									<Text>{items.carbohydratesKey}</Text>
									<Text>{items.carbohydrates}g</Text>
								</div>
								<div className="info_item">
									<Text>{items.sugarKey}</Text>
									<Text>{items.sugars}g</Text>
								</div>
								<div className="info_item">
									<Text>{items.saltKey}</Text>
									<Text>0g</Text>
								</div>
								<div className="info_item">
									<Text>{items.protienKey}</Text>
									<Text>0g</Text>
								</div>
							</div>
						)
					}
				]}
			/>
		)
	}

	if(type === 'information') {
		return (
			<Collapse
				bordered={false}
				defaultActiveKey={[defaultActiveKey]}
				expandIconPosition='end'
				items={[
					{
						key: defaultActiveKey,
						label: title,
						children: (
							<div className="info_wrapper">
								<div className="info_item">
									<Text>{items.countryKey}:</Text>
									<Text>{items.basic.country}</Text>
								</div>
								<div className="info_item">
									<Text>{items.alcoholKey}:</Text>
									<Text>{items.nutrition.alcohol}% vol</Text>
								</div>
							</div>
						)
					}
				]}
			/>
		)
	}

	if(type === 'recycleView') {
		return (
			<Collapse
				bordered={false}
				defaultActiveKey={[defaultActiveKey]}
				expandIconPosition='end'
				items={[
					{
						key: defaultActiveKey,
						label: title,
						children: (
							<RecycleView value={items} language={language} />
						)
					}
				]}
			/>
		)
	}

	if(type === 'only-text') {
		return (
			<Collapse
				bordered={false}
				defaultActiveKey={[defaultActiveKey]}
				expandIconPosition='end'
				items={[
					{
						key: defaultActiveKey,
						label: title,
						children: items.map((item, index) => {
							const str = (items.length - 1) === index ? '' : ', ';
							return (
								<Text key={index} className="text_only">{item}{str}</Text>
							)
						})
					}
				]}
			/>
		)
	}

	return (
		<Collapse
			bordered={false}
			defaultActiveKey={[defaultActiveKey]}
			expandIconPosition='end'
			style={{
				marginBottom: '5px'
			}}
			items={[
				{
					key: defaultActiveKey,
					label: title,
					children: items.map((item, index) => {
						const result = labels.find((x) => x.label === item);
						return (
							<RenderView key={index} result={result} />
						)
					})
				}
			]}
		/>
	)
}


const ViewOnly = ({
	product,
	formInfo,
	language,
	setLanguage
}) => {
	const download = () => {
		var link = document.createElement('a');
		link.download = `${product._id}.png`;
		link.style.margin = '10px';
		link.href = document.querySelector('.product-qr canvas').toDataURL()
		link.click();
	}

	const downloadPDF = () => {
		window.open(`${config.API_URL}/product/${product._id}/download?language=${language}`, '_blank')
	}

	const getURL = () => {
		return `${window.location.origin}/public/${product._id}/view`
	}

	const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 767);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	
	return (
		<div id="product-view-pdf">
			<div className="product_view_main">
				<div className="product_view_warapper">
					<div className="container">
						{
							isMobile && (
								<div className="product_view_buttons">
									<Select value={language} onChange={(e) => setLanguage(e) } className="language_dropdown" suffixIcon={<img src={chevronDownIcon} />}>
										<Option value="fr">French</Option>
										<Option value="it">Italian</Option>
										<Option value="en">English</Option>
										<Option value="es">Spanish</Option>
										<Option value="de">German</Option>
										<Option value="da">Danish</Option>
										<Option value="nl">Dutch</Option>
										<Option value="pt">Portuguese</Option>
										<Option value="ja">Japanese</Option>
									</Select>
								</div>
							)
						}
					
						<div className="product_view_header">
							<div className="product_view_image">
								<img src={`${config.PREFIX}/${product.filename}`} />
							</div>
							<div className="product_view_details">
								<Title level={3}>{product.basic.nameOfVintage}</Title>
								<Title level={4}>{product.basic.type}</Title>
								<Title level={5}>{product.basic.producer}</Title>
								{product.basic.harvestYear && <Text>{product.basic.yearKey}: {product.basic.harvestYear}</Text>}
							</div>
							<div className="product_view_buttons">
								{
									!isMobile && (
										<Select value={language} onChange={(e) => setLanguage(e) } className="language_dropdown" suffixIcon={<img src={chevronDownIcon} />}>
											<Option value="fr">French</Option>
											<Option value="it">Italian</Option>
											<Option value="en">English</Option>
											<Option value="es">Spanish</Option>
											<Option value="de">German</Option>
											<Option value="da">Danish</Option>
											<Option value="nl">Dutch</Option>
											<Option value="pt">Portuguese</Option>
											<Option value="ja">Japanese</Option>
										</Select>
									)
								}
								<QRCode
									bgColor="#fff"
									className="product-qr" 
									value={getURL()}
								/>
								<Button className="primary" onClick={downloadPDF}>
									{product.pdfKey}
								</Button>
								<Tooltip title="Download QRCode">
									<Button className="primary" onClick={download}>
										<DownloadOutlined />
									</Button>	
								</Tooltip>
							</div>
						</div>
						<div className="product_view_content">
							<LabelView 
								items={product}
								labels={[]}
								defaultActiveKey={'Information about wine'}
								title={product.informationKey}
								type="information"
							/>
							<LabelView 
								items={product.nutrition}
								labels={[]}
								title={product.nutritionKey}
								defaultActiveKey={'Nutrition Statement'}
								type="nutrition"
							/>
							<LabelView 
								items={product.ingredients.orderOfSelectedItems}
								labels={[]}
								defaultActiveKey={'Ingredients'}
								title={product.ingredientsKey}
								type='only-text'
							/>
							<LabelView 
								items={product.basic.labels}
								labels={formInfo.Labels_NEW}
								title={product.labelKey}
								defaultActiveKey={'Labels'}
							/>
							<LabelView 
								items={product.basic.RESPONSIBLE_CONSUMPTION}
								labels={formInfo.RESPONSIBLE_CONSUMPTION_NEW}
								title={product.responsibleKey}
								defaultActiveKey={'RESPONSIBLE CONSUMPTION'}
							/>
							<LabelView 
								items={product.basic.SUSTAINABILITY}
								labels={formInfo.SUSTAINABILITY_NEW}
								title={product.sustainabilityKey}
								defaultActiveKey={'SUSTAINABILITY'}
							/>

							{
								(product.basic.recyclInItaly && (language === 'en' || language === 'it')) && (
									<LabelView 
										items={product.basic}
										labels={[]}
										title={product.recycleKey}
										defaultActiveKey={'Recycle View'}
										type="recycleView"
										language={language}
									/>
								)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ViewOnly;
