import { Reorder } from "framer-motion"
import { useState } from "react"
import { List, Typography } from 'antd';
import dragIcon from '../../../assets/images/drag_icon.svg';

const { Title, Paragraph} = Typography;


const viewText = "Arrange your ingredients in the display order (note that the grapes will automatically be put in first position)."
const viewSubText = "Below 2% presence, the order of presentation does not matter.";


function ReOrderItems({
	orders,
	setOrder,
	fixedItems
}) {
  
  return (
	<div>
		<div>
			<Title level={4}>LIST:</Title>
			<Paragraph >{viewText}{viewSubText}</Paragraph>
		</div>

		{
			fixedItems.map((item, index) => {
				return (
					<List.Item key={index} className="list_item disabled">
						<img src={dragIcon} />
						{item}
					</List.Item>
				)
			})
		}
		<Reorder.Group 
			axis="y" 
			values={orders} 
			onReorder={setOrder}
			style={{
				'listStyle': 'none',
				'padding': 0,
				'margin': 0
			}}
		>
			{orders.map((item) => (
				<Reorder.Item 
					key={item}
					value={item}
					className="list_item"
				>
					<img src={dragIcon} /> {item}
				</Reorder.Item>
			))}
		</Reorder.Group>
	</div>
  )
}


export default ReOrderItems
