import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Switch, Card, Checkbox, Select, Form, Input, Tooltip, message, Upload } from 'antd';
import RecycleForm from './recycleForm';
import { useEffect, useState } from 'react';
import { WINE_CLASSIFICATION, WINE_COLORS, WINE_REGION, config } from '../../../utils/constant';
import { useSelector } from 'react-redux';
import imagePlaceholder from '../../../assets/images/image_placeholder.svg';

const { Option }  = Select;
const { Dragger } = Upload;

const props = {
	name: 'file',
	multiple: true,
	action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
	onChange(info) {
	  const { status } = info.file;
	  if (status !== 'uploading') {
		console.log(info.file, info.fileList);
	  }
	  if (status === 'done') {
		message.success(`${info.file.name} file uploaded successfully.`);
	  } else if (status === 'error') {
		message.error(`${info.file.name} file upload failed.`);
	  }
	},
	onDrop(e) {
	  console.log('Dropped files', e.dataTransfer.files);
	},
};

const BasicForm = ({
	onNextCick,
	initialValues,
	isDuplicateMode,
	imageURL,
	isEditMode
}) => {
	const { formInfo } = useSelector((state) => state.product);
	const [image, setImage] = useState('');
	const onFinish = (values) => {
		onNextCick({
			...values,
			image
		});
	};
	const [form] = Form.useForm();
	const values = Form.useWatch([], form);

	useEffect(() => {
		form.setFieldsValue({
			...initialValues,
			photo: ''
		});
	}, [initialValues]);


	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const dummyRequest = async ({ file, onSuccess }) => {    
		setTimeout(() => {
		   onSuccess("ok");
		}, 0);
	  }
	 

	const fileProps = {
		name: 'file',
		accept: 'image/*',
		multiple: false,
		maxCount: 1,
		customRequest: dummyRequest,
		onChange(info) {
			console.log(info);	
			setImage(info.file);
			form.setFieldsValue({
				...initialValues,
				photo: info.file
			});
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files[0]);
			setImage(e.dataTransfer.files[0]);
			form.setFieldsValue({
				...initialValues,
				photo: e.dataTransfer.files[0]
			});
		},
	};

	console.log({ image });
	const { recycleOptions, SUSTAINABILITY_NEW = [], RESPONSIBLE_CONSUMPTION_NEW = [], Labels = [], Labels_NEW =[] } = formInfo;	

	return (
		<div>
			<Form
				form={form}
				name="basic"
				layout="vertical"
				initialValues={initialValues}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					label="Photo"
					name="photo"
					rules={[
						{
							required: isEditMode ? false : true,
							message: 'Please enter value',
						},
					]}
				>
					{imageURL && (
						<Form.Item>
							<img src={`${config.PREFIX}/${imageURL}`} width={100} />
						</Form.Item>
					)}
					{/* <Dragger {...fileProps}>
						<img src={imagePlaceholder} />
						<p className="ant-upload-text">Drag & Drop image here to upload.</p>
						<p className="ant-upload-hint">or click here to Upload Image</p>
					</Dragger> */}
				
				{/* <Form.Item
					label="Photo"
					name="photo"
					rules={[
						{
							required: false,
							message: 'Please enter value',
						},
					]}
				> */}
					<Input  
						type="file" 
						multiple={false}
						accept='image/*'
						onChange={(e) => {
							console.log(e);
							setImage(e.target.files[0]);
						}} 
					/>
				</Form.Item>
				
				<div className='input_group'>
					<Form.Item
						label="Producer"
						name="producer"
						rules={[
							{
								required: true,
								message: 'Please enter value',
							},
						]}
						
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Name of the vintage"
						name="nameOfVintage"
						rules={[
							// {
							// 	required: true,
							// 	message: 'Please enter value',
							// },
						]}
					>
						<Input />
					</Form.Item>
				</div>
				<div className='input_group'>
					<Form.Item
						label="Country"
						name="country"
						rules={[
							{
								required: true,
								message: 'Please enter value',
							},
						]}
					>
						<Input />
					</Form.Item>
			
					<Form.Item
						label="Reference"
						name="reference"
						rules={[
							{
								max: 10,
								message: 'Value must be 10 characters or less.',
							},
						]}
					>
						<Input placeholder='Reference (10 characters max)'/>
					</Form.Item>
				</div>
				<div className='input_group'>
					<Form.Item
						label="EAN code"
						name="EANCode"
						rules={[
							{
								max: 13,
								message: 'Value must be 13 characters or less.',
							},
						]}
					>
						<Input placeholder='EAN code (13 characters max)' />
					</Form.Item>

					<Form.Item
						label="Harvest year"
						name="harvestYear"
					>
						<Input  placeholder='Harvest year' />
					</Form.Item>
				</div>
				<div className='input_group'>
					<Form.Item 
						label="Classification of the vintage" 
						name='classificationOfVintage'
						rules={[
							{
								required: true,
								message: 'Please select value',
							},
						]}
					>
						<Select placeholder="Select color" allowClear>
							<Option value="" disabled>Select classification of vintage</Option>
							{
								WINE_CLASSIFICATION.map((wine, index) => {
									return (
										<Option value={wine.value} key={index}>{wine.label}</Option>
									)
								})
							}
						</Select>
					</Form.Item>

					<Form.Item 
						label="Wine Region" 
						name='region'
						rules={[
							{
								required: true,
								message: 'Please select value',
							},
						]}
					>
						<Select placeholder="Select color" allowClear>
							<Option value="" disabled>Select regoin</Option>
							{
								WINE_REGION.map((wine, index) => {
									return (
										<Option value={wine.value} key={index}>{wine.label}</Option>
									)
								})
							}
						</Select>
					</Form.Item>
				</div>
				<div className='input_group'>
					<Form.Item 
						label="Color" 
						name='color'
					>
						<Select placeholder="Select color" allowClear>
							<Option value="" disabled>Select Color</Option>
							{
								WINE_COLORS.map((color, index) => {
									return (
										<Option value={color.value} key={index}>{color.label}</Option>
									)
								})
							}
						</Select>
					</Form.Item>

					<Form.Item
						label={
							<div>
								Type of product
								<Tooltip title="Name of appellation and full name of the wine, classication (if any)">
									<InfoCircleOutlined />
								</Tooltip>
							</div>
						}
						name="type"
					>
						<Input placeholder='Type of product'/>
					</Form.Item>
				</div>
				<Form.Item
					label="Rewarding mention"
					name="rewardingMention"
				>
					<Input placeholder='Rewarding mention'/>
				</Form.Item>

				<Form.Item
					label="Label"
					name="labels"
				>
					<Checkbox.Group>
						{
							Labels_NEW.map((item, index) => {
								return (
									<Checkbox key={index} value={item.label} style={{ lineHeight: '32px' }}>
										{item.label}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Responsible Consumption"
					name="RESPONSIBLE_CONSUMPTION"
				>
					<Checkbox.Group>
						{
							RESPONSIBLE_CONSUMPTION_NEW.map((item, index) => {
								return (
									<Checkbox key={index} value={item.label} style={{ lineHeight: '32px' }}>
										{item.label}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>
				<Form.Item
					label="Sustainability"
					name="SUSTAINABILITY"
				>
					<Checkbox.Group>
						{
							SUSTAINABILITY_NEW.map((item, index) => {
								return (
									<Checkbox key={index} value={item.label} style={{ lineHeight: '32px' }}>
										{item.label}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</Form.Item>

				<Form.Item 
					name="recyclInItaly"
					label="Recycling in Italy"
					valuePropName="checked"
				>
					<Switch />
				</Form.Item>

				{
					values?.recyclInItaly && (
						<RecycleForm recycleOptions={recycleOptions} />
					)
				}


				<div className='products_form_buttons'>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Next
						</Button>
					</Form.Item>
				</div>
			</Form>
		</div>
	)
}

export default BasicForm;
