import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	error: null,
	msg: null,
	products: [],
	selectedProduct: null,
	formInfo: {},
	fetchingInfo: false,
}

export const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		setFormInfo: (state, action) => {
			state.formInfo = action.payload;
		},
		cleanFormInfo: (state, action) => {
			state.selectedProduct = null;
		},
		setFetchingInfo: (state, action) => {
			state.fetchingInfo = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setMessage: (state, action) => {
			state.msg = action.payload;
			state.error = null;
		},
		setError: (state, action) => {
			state.error = action.payload;
		},
		setProducts: (state, action) => {
			state.products = action.payload;
		},
		setSelectedProduct: (state, action) => {
			state.selectedProduct = action.payload;
		}
	},
})

// Action creators are generated for each case reducer function
export const { 
	setLoading,
	setMessage,
	setError,
	setProducts,
	setSelectedProduct,
	setFormInfo,
	setFetchingInfo,
	cleanFormInfo,
 } = productSlice.actions

export default productSlice.reducer
