import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./style.css";
import ProductList from './list';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProduct, formProductInfo } from '../../redux/product/action';
import { Spin } from 'antd';

const Products = () => {

	const dispatch = useDispatch();
	const { loading, products } = useSelector(state => state.product);
	useEffect(() => {
		dispatch(fetchProduct());
		dispatch(formProductInfo());
	}, []);

	return (
		<div className='products_main'>
			<div className='container'>
				<div className='products_wrapper'>
					<div className='products_heading'>
						<h1>My List of Cuvees</h1>
						<Link to={'/products/add'} className='link_button'>Add Product</Link>
					</div>
					<Spin spinning={loading}>
						<ProductList products={products} />
					</Spin>
				</div>
				
			</div>
		</div>
	)
}

export default Products;
