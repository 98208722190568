import './style.css';

const Error = ({
	error
}) => {
	if(!error) {
		return null;
	}
	return (
		<div className='error-container'>
			<span className='error-text'>{error}</span>
		</div>
	)
}

export default Error;
