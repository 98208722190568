import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isLoggedIn: localStorage.getItem('token') ? true : false,
	user: null,
	loading: false,
	error: null,
	msg: null,
}

export const counterSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setMessage: (state, action) => {
			state.msg = action.payload;
		},
		setUser: (state, action) => {
			state.isLoggedIn = true;
			state.user = action.payload;
		},
		logout: (state) => {
			state.isLoggedIn = false;
			state.user = null;
		},
		setError: (state, action) => {
			state.error = action.payload;
		}
	},
})

// Action creators are generated for each case reducer function
export const { 
	setLoading,
	setUser,
	logout,
	setError,
	setMessage
 } = counterSlice.actions

export default counterSlice.reducer
