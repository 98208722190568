import { useDispatch, useSelector } from "react-redux";
import ProductForm from "./components/form";
import { addProduct, formProductInfo } from "../../redux/product/action";
import { useEffect } from "react";
import { setMessage } from "../../redux/product/slice";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

const AddProduct = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { fetchingInfo, msg, error } = useSelector((state) => state.product);

	useEffect(() => {
		dispatch(formProductInfo());
	}, []);

	useEffect(() => {
		if(msg === "ADD_SUCCESS") {
			dispatch(setMessage(null));
			navigate("/products")
		}
	}, [msg]);

	const submit = (formData) => {
		dispatch(addProduct(formData))
	}

	return (
		<div className="products_form">
			<div className="container">
				<Spin spinning={fetchingInfo}>
					{!fetchingInfo && <ProductForm submit={submit} />}
				</Spin>
			</div>
		</div>
	)
}

export default AddProduct;
