import React, { useEffect } from "react";
import LoginForm from "../../components/login";
import { useDispatch, useSelector } from "react-redux";
import { clientLogin } from "../../redux/auth/action";
import { useNavigate } from "react-router-dom";

const ClientLogin = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const  { isLoggedIn, error } = useSelector((state) => state.auth);

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/products')
		}
	}, [isLoggedIn]);

	const onSubmit = (body) => {
		dispatch(clientLogin(body));
	}

	return (
		<div>
			{/* Client login */}
			<LoginForm type='client' onSubmit={onSubmit} error={error}/>
		</div>
	)
}

export default ClientLogin;
