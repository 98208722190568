import React, { useEffect, useState } from 'react';
import { QrcodeOutlined, CopyOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Card, Tooltip, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { config } from "../../utils/constant";
import viewIcon from '../../assets/images/view_icon.svg';
import editIcon from '../../assets/images/edit_icon.svg';
import deleteIcon from '../../assets/images/delete_icon.svg';
import menuIcon from '../../assets/images/menu_icon.svg';



const ProductItem = ({
	product
}) => {	
	const items = [
		{
			label: (
				<Tooltip title="More information">
				  <Link to={`/products/${product._id}/view`}>
					<img src={viewIcon} alt="View" />
					<span>View</span>
				  </Link>
				</Tooltip>
			),
		 	 key: '0',
		},
		{
			label: (
				<Tooltip title="Modify">
				  <Link to={`/products/${product._id}/edit`}>
					<img src={editIcon} alt="Edit" />
					<span>Edit</span>
				  </Link>
				</Tooltip>
			),
			key: '1',
		},
		{
			label: (
				<Tooltip title="Duplicate">
				  <Link to={`/products/${product._id}/duplicate`}>
					<img src={deleteIcon} alt="Duplicate" />
					<span>Delete</span>
				  </Link>
				</Tooltip>
			),
		  	key: '2',
		},
	];

	const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 767);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className="product_sub_item">
			<div className="product_sub_item_content">
				<div className="product_sub_item_image">
					<img src={`${config.PREFIX}/${product.filename}`} />
				</div>
				<div className="product_sub_item_title">
					<h3>{product.basic.nameOfVintage}</h3>
					<p>{product.basic.type}</p>
					<p>({product.basic.harvestYear})</p>
				</div>
			</div>
			
				{
					isMobile ?
						<div className="product_sub_item_action">
							<Dropdown
								menu={{
									items,
								}}
								trigger={['click']}
								overlayClassName="product_item_action_dropdown"
							>
									<a onClick={(e) => e.preventDefault()}>
										<img src={menuIcon} />
									</a>
							</Dropdown>
						</div>
					:
						<div className="product_sub_item_action">
							{items.map((item) => (
								<div key={item.key}>
									{item.label}
								</div>
							))}
						</div>	
				}
				
			
		</div>
	)
}


const ProductList = ({
	products
}) => {
	return (
		<div className="products_list_main">
			<div className="products_list_item">
				<div className="products_list_item_heading">
					<div className="product_sub_item_content">
						<div className="product_sub_item_image"></div>
						<div className="product_sub_item_title">
							<h2>Producer</h2>
						</div>
					</div>
					<div className="product_sub_item_action">
						<h2>Actions</h2>
					</div>
				</div>
				{
					products.map((product) => {
						return (
							<ProductItem product={product} key={product._id} />
						)
					})
				}
				
			</div>
		</div>
	)
}

export default ProductList;
