import React, { useEffect } from 'react';
import ClientForm from './components/form';
import { useDispatch, useSelector } from 'react-redux';
import { addClient } from '../../redux/client/action';
import { setReset } from '../../redux/client/slice';
import { useNavigate } from 'react-router-dom';

const AddClient =  () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, msg, error } = useSelector(state => state.client);

	useEffect(() => {
		if(msg === 'ADD_SUCCESS') {
			dispatch(setReset(null));
			navigate('/clients');
		}

	}, [msg, error]);
	
	const onSubmit = (body) => {
		dispatch(addClient(body));
	}

	return (
		<div className='clients_form_main'>
			<div className='clients_form_wrapper'>
				<ClientForm onSubmit={onSubmit} loading={loading} error={error} navigate={navigate} />
			</div>
		</div>
	)
}

export default AddClient;
