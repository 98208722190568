import { config } from '../utils/constant';
import http from './http';


const URL = config.API_URL;

export const addClientAPI = (body) => {
	return http.post(URL+'/auth/client/create', body);
}

export const fetchClientAPI = () => {
	return http.get(URL+'/auth/client/list');
}

export const fetchClientInfoAPI = (id) => {
	return http.get(URL+'/auth/client/'+id);
}

export const updateClientInfoAPI = (id, body) => {
	return http.put(`${URL}/auth/client/${id}/update`, body)
}

export const deleteClientAPI = (id) => {
	return http.remove(`${URL}/auth/client/${id}`)
}
