import React, { useEffect } from 'react';
import ClientForm from './components/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientInfo, updateClientInfo } from '../../redux/client/action';
import { Spin } from 'antd';
import { setReset } from '../../redux/client/slice';

const EditClient =  () => {
	const params = useParams();
	const dispatch = useDispatch();
	const { msg, error, selectedClient, loading } = useSelector(state => state.client);
	const navigate = useNavigate();

	useEffect(() => {
		if(msg === 'UPDATE_SUCCESS') {
			dispatch(setReset(null));
			navigate('/clients');
		}

	}, [msg, error]);
	
	const onSubmit = (body) => {
		dispatch(updateClientInfo(body));
	}

	useEffect(() => {
		dispatch(fetchClientInfo(params.id));
	}, []);

	return (
		<div className='clients_form_main'>
			<div className='clients_form_wrapper'>
				<Spin spinning={loading}>
					{
						selectedClient && (
							<ClientForm
								loading={loading} 
								id={params.id} 
								isEditMode={true}
								selectedClient={selectedClient}
								onSubmit={onSubmit}
								navigate={navigate}
							/>
						)
					}
				</Spin>
			</div>
		</div>
	)
}

export default EditClient;
