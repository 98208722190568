import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useParams } from "react-router-dom";
import { fetchProductInfo, formProductInfo } from "../../redux/product/action";
import ViewOnly from "./components/view";
import { cleanFormInfo } from "../../redux/product/slice";

const systemLanguage = navigator.language || navigator.userLanguage;
const allowedLanguage = ['fr', 'it', 'en', 'es', 'de', 'da', 'nl', 'pt', 'ja'];
const ViewProdct = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const { selectedProduct, formInfo, loading } = useSelector((state) => state.product);
	const { id } = params;

	const extractCode = systemLanguage.split("-")[0] ?? null;
	const findLang = allowedLanguage.find((x) => x == extractCode) || null;
	const [language, setLanguage] = useState(findLang || 'en');
	
	useEffect(() => {
		dispatch(formProductInfo());

		return () => {
			dispatch(cleanFormInfo())
		}
	}, []);

	useEffect(() => {
		dispatch(fetchProductInfo({id, language}));
	}, [language])

	return (
		<div>
			<Spin spinning={loading}>
				{selectedProduct && (
					<ViewOnly 
						product={selectedProduct} 
						formInfo={formInfo}
						setLanguage={setLanguage}
						language={language}
					/>
				)}
			</Spin>
		</div>
	)
}

export default ViewProdct;
