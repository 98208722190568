import React from 'react';
import { Button, Card, Checkbox, Form, Input } from 'antd';
import "./style.css";
import { useTranslation } from 'react-i18next';
import Error from '../error';

const OTPVerifyForm = ({
	onSubmit,
	error,
	email
}) => {
	const { t } = useTranslation();

	const onFinish = (values) => {
		onSubmit(values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	
	return (
		<div className='login_main'>
			<div className='login_container'>
				<div className='login_form'>
					<h2>Change password</h2>
					<Form
						name="basic"
						layout="vertical"
						labelCol={{
							span: 8,
						}}
						initialValues={{
							email: email,
							password: '',
							OTP: '',
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<Form.Item
							label="Identifier"
							name="email"
							rules={[
								{
									required: true,
									message: 'Please enter your email',
								},
								{
									type: 'email',
									required: true,
									message: 'Please enter valid email',
								},
							]}
						>
							<Input disabled />
						</Form.Item>

						<Form.Item
							label="Password"
							name="password"
							rules={[
							{
								required: true,
								message: 'Please enter your password!',
							},
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							label="OTP"
							name="OTP"
							rules={[
							{
								required: true,
								message: 'Please enter your otp!',
							},
							]}
						>
							<Input />
						</Form.Item>
				
						<Error error={error}/>
						<Form.Item>
							<Button type="primary" htmlType="submit" className='login-btn'>
								Change password
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	)
}
export default OTPVerifyForm;
