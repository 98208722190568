import {
	Routes,
	Route,
	useLocation,
	useNavigate,
  } from "react-router-dom";
import ClientLogin from './pages/client-login';
import AdminLogin from './pages/admin-login';
import NoMatch from './pages/NoMatch';
import Clients from './pages/clients';
import HeaderView from './components/header';
import { useState } from 'react';
import './App.css';
import { Layout  } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import Products from "./pages/products";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { setUser } from "./redux/auth/slice";
import AddClient from "./pages/clients/add";
import EditClient from "./pages/clients/edit";
import AuthWrapper from "./authWrapper";
import AddProduct from "./pages/products/add";
import EditProduct from "./pages/products/edit";
import ViewProdct from "./pages/products/view";
import DuplicateProduct from "./pages/products/duplicate";
import ForgotPassword from "./pages/forgot-password";

const { Content } = Layout;

const RouteWrapper = ({
	user,
	isLoggedIn
}) => {
	return (
		<Routes>
			<Route path="login" element={<ClientLogin />} />
			<Route path="forgot-password" element={<ForgotPassword />} />
			<Route path="admin-login" element={<AdminLogin />} />
			
	
			{
				(user && user?.type === 'admin') && (
					<Route path='clients' >
						<Route 
							path=''
							element={
								<AuthWrapper isLoggedIn={isLoggedIn}><Clients /></AuthWrapper>
							}
						/>
						<Route 
							path='add'
							element={
								<AuthWrapper isLoggedIn={isLoggedIn}><AddClient /></AuthWrapper>
							}
						/>
						<Route 
							path=':id/edit'
							element={
								<AuthWrapper isLoggedIn={isLoggedIn}><EditClient isEditMode={true} /></AuthWrapper>
							}
						/>
					</Route>
				)
			}
			
			{
				(user && user?.type === 'client') && (
					<Route path='products' >
						<Route 
							path=''
							element={
								<AuthWrapper isLoggedIn={isLoggedIn}><Products /></AuthWrapper>
							}
						/>
						<Route 
							path='add'
							element={
								<AuthWrapper isLoggedIn={isLoggedIn}><AddProduct /></AuthWrapper>
							}
						/>
						<Route 
							path=':id/edit'
							element={
								<AuthWrapper isLoggedIn={isLoggedIn}><EditProduct isEditMode={true} /></AuthWrapper>
							}
						/>
						<Route 
							path=':id/duplicate'
							element={
								<AuthWrapper isLoggedIn={isLoggedIn}><DuplicateProduct isEditMode={true} /></AuthWrapper>
							}
						/>
						<Route 
							path=':id/view'
							element={
								<AuthWrapper isLoggedIn={isLoggedIn}><ViewProdct /></AuthWrapper>
							}
						/>
					</Route>
				)
			}

			<Route 
				path='public/:id/view'
				element={
					<ViewProdct />
				}
			/>

			<Route 
				path="*" 
				element={
					<AuthWrapper isLoggedIn={isLoggedIn}>
					<NoMatch isLoggedIn={isLoggedIn} user={user}/>
					</AuthWrapper>
				} 
			/>
		</Routes>
	)
};

// const RedictUser = ({
// 	user
// }) => {
// 	if (!user) {
// 		return redirect("/login");
// 	}
// 	return redirect('/clients');
// }

function App() {

	const { isLoggedIn, user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [isLoaded, setLoaded] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const token = localStorage.getItem('token');
		if(token) {
			const user = jwtDecode(token);
			dispatch(setUser(user));
			if(user && user.type == 'admin' && location.pathname == "/") {
				navigate('/clients');
			}
		}

		setTimeout(() => {
			setLoaded(true);
		}, 300);
	}, []);

	return (
		<div className="App">
			{(isLoggedIn && isLoaded) && <HeaderView user={user}/>}
			<Content>
				{
					isLoaded && (
						<RouteWrapper user={user} isLoggedIn={isLoggedIn}/>
					)
				}
			</Content>
		</div>
	);
}

export default App;
