import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	list: [],
	loading: false,
	msg: null,
	error: null,
	selectedClient: null,
}

export const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setMessage: (state, action) => {
			state.msg = action.payload;
			state.error = null;
		},
		setError: (state, action) => {
			state.error = action.payload;
		},
		setReset: (state) => {
			state.error = null;
			state.msg = null;
		},
		setClients: (state, action) => {
			state.list = action.payload;
			state.selectedClient = null;
			state.error = null;
			state.msg = null;
		},
		setSelectedClient: (state, action) => {
			state.selectedClient = action.payload;
		}
	},
})

// Action creators are generated for each case reducer function
export const { 
	setLoading,
	setMessage,
	setReset,
	setError,
	setClients,
	setSelectedClient
 } = clientSlice.actions

export default clientSlice.reducer
