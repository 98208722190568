import axios from 'axios';


const getHeader = () => {
	const token = localStorage.getItem('token') || '';
	return {
		'authorization': localStorage.getItem('token')
	}
}

const get = (url) => {
	return axios.get(url, {
		headers: getHeader()
	});
}

const post = (url, body) => {
	return axios.post(url, body, {
		headers: getHeader()
	});
}

const put = (url, body) => {
	return axios.put(url, body, {
		headers: getHeader()
	});
}

const remove = (url) => {
	return axios.delete(url, {
		headers: getHeader()
	});
}

export default {
	get,
	post,
	put,
	remove
}
 