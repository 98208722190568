import { Button, Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const NoMatch = ({
	user,
}) => {
	const navigate = useNavigate();

	const onClick = () => {
		if (user && user.type === 'admin') {
			navigate('/clients');
		}
		if (user && user.type === 'client') {
			navigate('/products');
		}
		
	}
	return (
		<Card>
			<h3>Invalid Route</h3>
			<Button type="button" onClick={() => onClick()}>Go To Home</Button>
		</Card>
	)
}

export default NoMatch;
