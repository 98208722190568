import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchClient, deleteClient } from '../../redux/client/action';
import { Button, Popconfirm, Space, Table, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import editIcon from '../../assets/images/edit_icon.svg';
import deleteIcon from '../../assets/images/delete_icon.svg';
import menuIcon from '../../assets/images/menu_icon.svg';
import "./style.css";



const Clients = () => {

	const dispatch = useDispatch();
	const { list, loading } = useSelector((state) => state.client);

	useEffect(() => {
		dispatch(fetchClient());
	}, []);

	const remove = (id) => {
		dispatch(deleteClient(id));
	}

	const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 767);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const items = [
		{
			label: (
				<Link className='edit_button'>
					<Button><img src={editIcon} /><span>Edit</span></Button>
				</Link>
			),
		 	 key: '0',
		},
		{
			label: (
				<Popconfirm
					title="Are you sure you want to delete?"
				>
					<Button><img src={deleteIcon} /><span>Delete</span></Button>
				</Popconfirm>
			),
			key: '1',
		}
	];

	const columns = [
		{
			title: 'ID#',
			dataIndex: '',
			render: (__, _, index) => {
				return (
					<span>{index+1}</span>
				)
			}
		},
		{
			title: 'Client Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: isMobile ? '' : 'Action',
			dataIndex: '',
			key: '_id',
			fixed: 'right',
			render: (data) => {
				return (
					<div key={data._id}>
						{
							isMobile ?
							<div className="product_sub_item_action">
								<Dropdown
									menu={{
										items,
									}}
									trigger={['click']}
									overlayClassName="client_item_action_dropdown "
								>
										<a onClick={(e) => e.preventDefault()}>
											<img src={menuIcon} />
										</a>
								</Dropdown>
							</div>
							:
							<div>
								<Link to={`/clients/${data._id}/edit`} className='edit_button'>
									<Button><img src={editIcon} /></Button>
								</Link>
								
								<Popconfirm
									title="Are you sure you want to delete?"
									onConfirm={() => {
										remove(data._id);
									}}
								>
									<Button><img src={deleteIcon} /></Button>
								</Popconfirm>
							</div>
						}
						
					</div>
				);
			},
		},
	];


	return (
		<div className='clients_main'>
			<div className='clients_wrapper'>
				<div className='container'>
					<div className="clients_header">
						<h2>Clients</h2>
						<Link to={'/clients/add'} className='add_button'>Add Client</Link>
					</div>
					<div className='clients_table_container'>
						<div className='clients_table'>
							<Table
								pagination={{
									defaultPageSize: 20
								}}
								size='small'
								loading={loading} 
								columns={columns} 
								dataSource={list}
								rowKey={'_id'}

							></Table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Clients;
