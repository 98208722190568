import { Button, message, Steps, theme } from 'antd';
import { useEffect, useState } from 'react';
import IngredientsForm from './ingredientsForm';
import BasicForm from './basicForm';
import NutritionForm from './nutritionForm';

const ProductForm = ({
	submit,
	formValues = {},
	isEditMode = false,
	isDuplicateMode = false
}) => {
	const [current, setCurrent] = useState(0);
	const [state, setState] = useState((Object.keys(formValues).length > 0 && formValues) ? formValues : {
		basic: {
			photo: '',
			producer: '',
			nameOfVintage: '',
			reference: '',
			EANCode: '',
			harvestYear: '',
			color: '',
			type: '',
			rewardingMention: '',
			labels: [],
			RESPONSIBLE_CONSUMPTION: [],
			SUSTAINABILITY: [],
			recyclInItaly: false,
			image: null,
			classificationOfVintage: 'Wine of France',
			region: '',

		},
		ingredients: {},
		nutrition: {},
	})

	const steps = [
		{
			title: 'Information',
		},
		{
			title: 'Ingredients',
		},
		{
			title: 'Nutrition Statement',
		},
	];

	const submitData = (submitData) => {

		const formData = new FormData();
		formData.append('basic', JSON.stringify(submitData.basic));
		if(submitData.basic.image) {
			formData.append('file', submitData.basic.image, submitData.basic.image.name)
		}
		formData.append('oldImage', formValues.filename);
		formData.append('ingredients', JSON.stringify(submitData.ingredients));
		formData.append('nutrition', JSON.stringify(submitData.nutrition));
		// console.log({formData});
		submit(formData);
	}


	const clickToNext = (data, key) => {
		window.scrollTo(0, 0);
		if(current === 2) {
			const newObject = {
				...state,
				[key]: data
			}
			setState(newObject);
			submitData(newObject);
			return;
		}
		setCurrent(current + 1);
		setState({
			...state,
			[key]: data
		});
	}

	const clickToPrev = () => {
		if(current === 0) {
			return;
		}
		setCurrent(current - 1);
	}

	return (
		<div className='products_form_wrapper'>
			<Steps size='small' current={current} items={steps} type="navigation" className="site-navigation-steps" />
			<div className='products_form_content'>
				{
					current === 0 && (
						<BasicForm
							isEditMode={isEditMode}
							isDuplicateMode={isDuplicateMode}
							initialValues={state.basic}
							imageURL={formValues.filename}
							onNextCick={(data) => clickToNext(data, 'basic')}
						/>
					)
				}
				{
					current === 1 && (
						<IngredientsForm
							initialValues={state.ingredients}
							onNextCick={(data) => clickToNext(data, 'ingredients')}
							onPrevClick={() => clickToPrev()}
						/>
					)
				}
				{
					current === 2 && (
						<NutritionForm
							initialValues={state.nutrition}
							onNextCick={(data) => clickToNext(data, 'nutrition')}
							onPrevClick={() => clickToPrev()}
							isEditMode={isEditMode}
							isDuplicateMode={isDuplicateMode}
						/>
					)
				}
			</div>
			{/* <div className='products_form_buttons'>
				{current > 0 && (
					<Button onClick={() => clickToPrev()}>Previous</Button>
				)}
				{
					(current < 2) && <Button onClick={() => clickToNext()}>Next</Button>
				}
				{
					(current === 2) && <Button onClick={() => clickToNext()}>Submit</Button>
				}
			</div> */}
		</div>
	)
}

export default ProductForm;
